import React from "react"
import { graphql, Link } from "gatsby"
// import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"

// import TruncateMarkup from "react-truncate-markup"
import Breadcrumbs from "../components/ui/breadcrumbs"

import Layout from "../components/layout"

const LongevitySessions = ({ data, location }) => {
    const content = data.allContentfulLongevitySessions.nodes.content
    const title = data.allContentfulLongevitySessions.nodes.title
    const date = data.allContentfulLongevitySessions.nodes.createdAt

    const options = {
        renderMark: {},
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => {
                return <p>{children}</p>
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return <p>{children}</p>
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return <p>{children}</p>
            },
        },
    }

    return (
        <Layout location={location}>
            <div className="container">
                <Breadcrumbs loc="longevity sessions" />
                <div className="title">
                    <h1>Longevity Sessions</h1>
                    <p>
                        There is a direct correlation between purpose and
                        longevity. In this series of short talks, with folks in
                        and around the Longevity Lab community, we get little
                        insights and inspirations we have learned from each
                        other, and our life experiences that make us
                        stronger&nbsp;together.
                    </p>
                    <p>
                        We all want to live fully and be well. Underneath the
                        physical strength building, our practice is grounded in
                        purpose. We do the work, so that we can meet our
                        potential and show up in the world. We find that purpose
                        and human connectivity are what unites us all. No
                        regrets – just journeys&nbsp;here.
                    </p>
                </div>
            </div>
            {data.allContentfulLongevitySessions.nodes.length > 1 ? (
                <div className="container longevity-sessions-page">
                    {data.allContentfulLongevitySessions.nodes
                        .slice(1)
                        .map(({ title, content, createdAt, slug }) => (
                            <Link className="item" to={slug} key={title}>
                                <div className="title">
                                    <h2>{title}</h2>
                                    <p className="date">{createdAt}</p>
                                </div>
                                {/* <TruncateMarkup ellipsis="..." lines={7}>
                                <p className="">{renderRichText(content, options)}</p>
                            </TruncateMarkup> */}
                                <b>Read more</b>
                            </Link>
                        ))}
                </div>
            ) : (
                <div className="container" style={{ marginBottom: "3rem" }}>
                    <h3>Coming soon...</h3>
                </div>
            )}
        </Layout>
    )
}

export const query = graphql`
    {
        allContentfulLongevitySessions {
            nodes {
                title
                slug
                createdAt(formatString: "MM-DD-YYYY")
                content {
                    raw
                }
            }
        }
    }
`

export default LongevitySessions
